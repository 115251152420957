<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>About <span>IRonSoftTech</span></h2>
            <p>IRonsoftTech Insight Hub is a cutting-edge IT and Analytics company committed to revolutionizing the way businesses harness the power of technology and data. Our primary focus lies in providing intelligent solutions that empower organizations to make informed decisions, drive innovation, and achieve unparalleled success.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>We Are <span>IT & Analytics Company</span></h2>
                        <p>At Ronsofttech Tech Insight Hub, our mission is to be the catalyst for digital transformation. We strive to create a seamless fusion of technology and insight, enabling our clients to navigate the complexities of the modern business landscape with confidence and clarity.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Custom Software Development</li>
                        <li><i class="fas fa-check"></i>Data Collection</li>
                        <li><i class="fas fa-check"></i>Data Analysis</li>
                        <li><i class="fas fa-check"></i>Report Writing</li>
                        <li><i class="fas fa-check"></i>Innovative Tech Solutions</li>
                        <li><i class="fas fa-check"></i>Data Science and AI</li>
                        <li><i class="fas fa-check"></i>Strategic Consulting</li>
                    </ul>
                    <p>Transforming Data into Decisions, Innovation into Impact.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.jpg" alt="about">
                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
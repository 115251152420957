<div id="blog" class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Our News</h4>
            <h2>Latest Blog <span>Posts</span></h2>
            <p>At IRonSoftTech, the pursuit of innovation isn't just a goal;
                it's embedded in our DNA. Our latest venture is a testament to
                this commitment — a groundbreaking tech marvel that pushes the
                boundaries of what's possible in the digital realm.</p>
        </div>
        <div class="blog-slides">
            <owl-carousel-o [options]="blogSlides">
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Business</span>
                        <h3><a routerLink="/blog-details">The best gear for
                                starting a small business</a></h3>
                        <p>In a world driven by technology, businesses that
                            embrace digital transformation gain a competitive
                            edge. Whether it's optimizing
                            processes or enhancing customer experiences, a
                            digital-first approach is key...</p>
                        <a routerLink="/blog-details" title="Read More"
                            class="link-btn"><i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg1">
                        <span>Empowering Your Business Journey</span>
                        <h3><a routerLink="/blog-details">Data Analytics</a></h3>
                        <p>Data analytics isn't just a buzzword; it's a
                            transformative force that can reshape the way
                            businesses operate. By embracing the power of data
                            analytics, your business can gain a competitive
                            edge, foster innovation, and make strategic
                            decisions that drive success in an ever-evolving
                            digital landscape...</p>
                        <a routerLink="/blog-details" title="Read More"
                            class="link-btn"><i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg2">
                        <span>Tech</span>
                        <h3><a routerLink="/blog-details">Get your first
                                business suit right with these tips</a></h3>
                        <p> Your first business suit is more than an outfit—it's
                            a canvas for your tech story. By infusing subtle
                            tech-inspired elements, you can create a style that
                            reflects your passion and professionalism in your
                            business. Step into
                            the tech world with confidence, style, and a suit
                            that speaks your language...</p>
                        <a routerLink="/blog-details" title="Read More"
                            class="link-btn"><i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-blog-item bg4">
                        <span>Marketing & Trend</span>
                        <h3><a routerLink="/blog-details">10 Hot Marketing
                                Trends You Need to Implement</a></h3>
                        <p> In the fast-paced world of marketing, simplicity
                            often holds the key to success. By understanding
                            your audience, embracing visual storytelling, and
                            staying attuned to the latest trends, you can
                            navigate the marketing landscape with
                            confidence...</p>
                        <a routerLink="/blog-details" title="Read More"
                            class="link-btn"><i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-blog-item bg5">
                        <span>The Power of Data</span>
                        <h3><a routerLink="/blog-details">A Strategic
                                Asset</a></h3>
                        <p> In the digital age, data is a strategic asset that
                            holds the potential to unlock valuable insights.
                            Whether it's customer behavior, market trends, or
                            operational efficiency, data analytics empowers
                            businesses to make informed decisions based on
                            evidence, not guesswork...</p>
                        <a routerLink="/blog-details" title="Read More"
                            class="link-btn"><i
                                class="fas fa-arrow-right"></i></a>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>
<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>IRonsoft</span>Tech</a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
        <p>© Proudly Owned by <a href="https://envytheme.com/" target="_blank">IRonSoftTech 2024</a></p>
    </div>
</footer>


<ngx-scrolltop></ngx-scrolltop>
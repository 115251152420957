<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Looking for exclusive services?</h4>
                <h2>Get The Best For Your Business</h2>
                <p>Whether it's customer support, technical assistance,
                     or any other form of aid, we are here to ensure that your business receives the highest level of support, allowing you to thrive and achieve your goals.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contact Us</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">View Work</a>
            </div>
        </div>
    </div>
</div>
<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>Innovation Meets Intelligence</h4>
            <h2>Welcome to <span>IRonSoftTech</span></h2>
            <p>IRonSoftTech Insight Hub is a cutting-edge IT and Analytics
                company committed to revolutionizing the way businesses harness
                the power of technology and data.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Innovation</h3>
                    <p>A commitment to continuous innovation, exploring emerging
                        technologies, and staying at the forefront of industry
                        trends to provide forward-thinking solutions for
                        clients.</p>
                    <a (click)="onClick('about')" title="Read More"
                        class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Client-Centric Approach</h3>
                    <p>Placing the client at the center of operations,
                        understanding their goals and challenges, and tailoring
                        services to ensure maximum value and impact.</p>
                    <a (click)="onClick('about')" title="Read More"
                        class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Data Security and Compliance</h3>
                    <p>Prioritizing the security and compliance of client data,
                        adhering to the highest industry standards to guarantee
                        confidentiality and integrity.</p>
                    <a (click)="onClick('about')" title="Read More"
                        class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                        <h3>Expertise and Excellence</h3>
                        <p>Maintaining a team of seasoned professionals and
                            experts in technology, analytics, and business
                            strategy to deliver high-quality and effective
                            solutions.</p>
                        <a (click)="onClick('about')" title="Read More"
                            class="link-btn"><i
                                class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                        <h3>Digital Transformation</h3>
                        <p>Serving as a catalyst for digital transformation,
                            helping clients navigate the complexities of the
                            modern business landscape with confidence and
                            clarity.</p>
                        <a (click)="onClick('about')" title="Read More"
                            class="link-btn"><i
                                class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                        <h3>Result-Driven Approach</h3>
                        <p> Focusing on delivering measurable and impactful
                            results for clients, whether through advanced
                            analytics, innovative tech solutions, or strategic
                            consulting.</p>
                        <a (click)="onClick('about')" title="Read More"
                            class="link-btn"><i
                                class="fa fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
    </div>
<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How We <span>Work</span></h2>
            <p>At IRonSoftTech, our approach to creative endeavors is a fusion
                of
                methodical processes, boundless imagination, and a commitment to
                turning visions into reality.
                Here's a glimpse into how we work.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Vision Exploration and Insight Gathering</h3>
                    <p>Understanding Your Vision: Our journey begins with deep
                        consultations to comprehend your vision and objectives.
                        We gather key insights, ensuring alignment with your
                        unique identity.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Strategic Planning and Blueprint Creation</h3>
                    <p>Blueprint for Success: We formulate a strategic plan and
                        create a project blueprint outlining objectives,
                        timelines, and the creative direction we envision.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Collaborative Ideation and Concept Development</h3>
                    <p>Fueling Creative Sparks: Through collaborative ideation
                        sessions, diverse perspectives contribute to cultivating
                        imaginative ideas. Refined concepts align seamlessly
                        with your vision.</p>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Iterative Design and Client Feedback</h3>
                    <p>Precision in Every Detail: Our designers engage in an
                        iterative design process, refining and polishing
                        concepts with precision. Transparent client feedback
                        guides the fine-tuning process.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Prototyping, Testing, and Approval</h3>
                    <p>Ensuring Seamless Functionality: Prototypes simulate user
                        experiences, followed by rigorous testing. We present
                        refined concepts for your approval, collaborating
                        closely for final adjustments.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Finalization, Delivery, and Ongoing Support</h3>
                    <p>Sealing Excellence: With your approval, we finalize
                        designs meticulously. The finished product is delivered,
                        ready to make a lasting impact. Our commitment extends
                        with post-launch support, ensuring ongoing
                        excellence.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Who We <span>Are</span></h2>
            <p>IRonSoftTech is a forward-thinking IT and Analytics company dedicated to transforming businesses through the strategic use of technology and data.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>We are professional</h3>
                    <p>We are committed to high standards, ethical conduct, and expertise in our field.</p>
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>We are passionate</h3>
                    <p>We are passionate in transforming challenges into opportunities and fostering a culture of excellence.</p>
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>We are creative</h3>
                    <p>We embrace and  foster fresh ideas in our business environment.</p>
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>We are designer</h3>
                    <p>We got a uniques set of skills and a creative mindset that can contribute significantly to various industries and projects.</p>
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>We are unique</h3>
                    <p>Our unique perspective, experiences, and background that brings fresh insights to problem-solving and decision-making.</p>
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>We are support</h3>
                    <p>We provide excellent customer, technical or support in other ways.</p>
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>Services</span></h2>
            <p>At IRonSoftTech, our diverse suite of services empowers
                businesses to thrive in the digital landscape. Whether it's
                creating a captivating online presence, optimizing data
                utilization, or implementing cutting-edge AI solutions, we are
                your partner in achieving digital excellence.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Data Collection</h3>
                        <p>Harness the power of data with our comprehensive data
                            collection services. We implement effective
                            strategies to gather valuable insights for informed
                            decision-making.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img2.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Data Reporting</h3>
                        <p>Translate complex data into meaningful insights. Our
                            data reporting services provide clear, visually
                            compelling reports, enabling you to make informed
                            business decisions.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img3.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Data Science and AI</h3>
                        <p>Unlock the potential of artificial intelligence and
                            data science. Leverage predictive insights, automate
                            processes, and discover new opportunities to stay
                            ahead in your industry.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img4.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Analytics</h3>
                        <p>Drive business success with advanced analytics. We
                            transform raw data into actionable insights,
                            providing you with a competitive edge and a roadmap
                            for strategic growth.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img5.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Web Design & Development</h3>
                        <p>Craft visually stunning and functionally robust
                            websites tailored to your unique brand identity. Our
                            development team ensures seamless user experiences
                            and optimal performance.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img6.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>Elevate user satisfaction with intuitive and visually
                            appealing interfaces. Our UI/UX design experts
                            create engaging digital experiences that resonate
                            with your audience.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img7.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Strategic Consulting</h3>
                        <p>Collaborate with our experts to align your technology
                            and business objectives. Our strategic consulting
                            services ensure a holistic approach, fostering a
                            roadmap to success.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img8.jpg"
                            alt="services-img">
                        <div class="icon">
                            <i class="fas fa-cogs"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Software Development</h3>
                        <p>Empower your business with custom software solutions
                            tailored to your unique needs. Our development team
                            ensures seamless integration and optimal
                            performance.</p>
                    </div>
                </div>
        </div>
    </div>
</div>
<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Highly Creative Solutions</h4>
                        <h2>About <span>Strategy</span></h2>
                        <p>It involves making decisions on allocating resources, setting priorities, and guiding the organization toward success. Here are key aspects of business strategy:      </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Innovation</li>
                        <li><i class="fa fa-check"></i>Strategic Planning</li>
                        <li><i class="fa fa-check"></i>Creativity</li>
                        <li><i class="fa fa-check"></i>Data Integrity</li>
                        <li><i class="fa fa-check"></i>Goals and Objectives</li>
                        <li><i class="fa fa-check"></i>Risk Management</li>
                        <li><i class="fa fa-check"></i>Awesome Design</li>
                    </ul>
                    <p>Effective strategic management is crucial for the long-term success and sustainability of an organization.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
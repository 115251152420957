<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Why We <span>Are Different</span></h2>
            <p>At IRonSoftTech, we're not just different; we're a breed apart.
                We invite you to experience the IRonSoftTech advantage — where
                innovation, collaboration, and excellence converge to elevate
                your digital journey.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1"
                aria-controls="speed-flexibility" checked>
            <label for="tab1">Holistic Innovation</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2"
                aria-controls="profeesional-work">
            <label for="tab2">Client-Centric Excellence</label>
            <!-- Tab 3 -->
            <input type="radio" name="tabset" id="tab3"
                aria-controls="experienced-staff">
            <label for="tab3">Versatile Expertise</label>
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4"
                aria-controls="custom-support">
            <label for="tab4">Agile Adaptability</label>

            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/diifferent-image3.jpg"
                                    alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Holistic Innovation</h3>
                                <p>Beyond the Ordinary: At IRonSoftTech,
                                    innovation is not a checkbox; it's a way of
                                    life. We go beyond the ordinary, constantly
                                    exploring new horizons to deliver solutions
                                    that redefine industry standards.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Retina
                                        Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Modern
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Awesome
                                        Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-image2.jpg"
                                    alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Client-Centric Excellence</h3>
                                <p>Your Success, Our Priority: We're not just
                                    service providers; we're partners in your
                                    success. Our client-centric approach ensures
                                    that every solution we craft is tailored to
                                    your goals, challenges, and aspirations.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Retina
                                        Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Modern
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Awesome
                                        Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/diifferent-image3.jpg"
                                    alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Versatile Expertise</h3>
                                <p>A Team of Visionaries: IRonSoftTech boasts a
                                    team of versatile experts with diverse
                                    skills, from tech wizards to creative
                                    maestros. This multidisciplinary approach
                                    allows us to offer comprehensive solutions
                                    that address every facet of your needs.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Retina
                                        Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Modern
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Awesome
                                        Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/diiferent-image4.jpg"
                                    alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Agile Adaptability</h3>
                                <p>Adapting to Tomorrow: In a dynamic digital
                                    landscape, adaptability is key. IRonSoftTech
                                    embraces an agile mindset, ensuring our
                                    solutions evolve alongside technological
                                    advancements, keeping you ahead in the
                                    digital race.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Retina
                                        Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Modern
                                        Design</li>
                                    <li><i class="fas fa-check"></i> Awesome
                                        Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <a class="navbar-brand" routerLink="/"><span>iRonSoft</span>TECH</a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('about')">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('team')">Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('services')">Services</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('work')">Work</span></li> -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="onClick('pricing')">Pricing</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="onClick('blog')">Blog</span></li>
                <li class="nav-item"><span class="nav-link" (click)="onClick('contact')">Contact</span></li>
            </ul>
            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('home')">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('about')">About</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('team')">Team</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('services')">Services</span></li>
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('work')">Work</span></li> -->
                <!-- <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('pricing')">Pricing</span></li> -->
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('blog')">Blog</span></li>
                <li class="nav-item"><span class="nav-link" (click)="toggleClass(); onClick('contact')">Contact</span></li>
            </ul>
        </div>
    </div>
</nav>
<div id="team" class="team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h4>Meet IRonSoftTech</h4>
            <h2>Our Creative <span>Team</span></h2>
            <p>At the heart of innovation and artistic brilliance, Our team is
                more than just a creative team—it's a force of imagination,
                passion, and ingenuity.
                Comprising individuals with diverse talents and a shared
                commitment to excellence, our creative team is poised to elevate
                your vision and bring it to life.</p>
        </div>
        <div class="team-slides">
            <owl-carousel-o [options]="teamSlides">

                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-2.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Jackline Chepkorir</h3>
                            <span class="post">Data Analyst</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-1.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Ronald Sigei</h3>
                            <span class="post">Full Stack Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-3.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Ronald Sigei</h3>
                            <span class="post">Full stack Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template> -->
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-4.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Alex Mongare</h3>
                            <span class="post">Back-End Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-5.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Haron Rono</h3>
                            <span class="post">Back-End Developer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template> -->
                <ng-template carouselSlide>
                    <div class="single-team-box">
                        <div class="image">
                            <img src="assets/img/team-6.jpg" alt="team-img">
                        </div>
                        <div class="content">
                            <h3 class="title">Manaseh Kigamba</h3>
                            <span class="post">Photographer</span>
                        </div>
                        <div class="social">
                            <ul>
                                <li><a href="#" target="_blank"
                                        class="fab fa-facebook-f"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-twitter"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-instagram"></a></li>
                                <li><a href="#" target="_blank"
                                        class="fab fa-linkedin-in"></a></li>
                            </ul>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>